.new-offers-badge {
    position: absolute !important;
    margin-top: -40px;
    margin-left: -20px;
}

.new-offers-image {
    transition: transform .15s;
}

.new-offers-image:hover {
    transform: scale(1.1);
}