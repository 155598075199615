
.home-details-label {
    position: absolute !important;
    right: 20%;
    left: 20%;
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;
}

.home-details-label .MuiBadge-badge {
    width: 100%;
}