
.search-bar-dropdown-input {
    vertical-align: unset !important;
}

.search-bar-dropdown-input-input {
    line-height: 30px !important;
    height: 45px;
}

.search-bar-dropdown-input #price, .search-bar-dropdown-input #rooms, #search-bar-location {
    font-size: 16px;
}

.search-bar-location-text-input label {
    font-size: 16px;
}